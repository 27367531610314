export const projectConstants = {


    GET_PROJECT_WEBSITE_REQUEST: 'GET_PROJECT_WEBSITE_REQUEST',
    GET_PROJECT_WEBSITE_SUCCESS: 'GET_PROJECT_WEBSITE_SUCCESS',
    GET_PROJECT_WEBSITE_FAILURE: 'GET_PROJECT_WEBSITE_FAILURE',

    LIST_ALL_PROJECTS_WEBSITE_REQUEST: 'LIST_ALL_PROJECTS_WEBSITE_REQUEST',
    LIST_ALL_PROJECTS_WEBSITE_SUCCESS: 'LIST_ALL_PROJECTS_WEBSITE_SUCCESS',
    LIST_ALL_PROJECTS_WEBSITE_FAILURE: 'LIST_ALL_PROJECTS_WEBSITE_FAILURE'


};